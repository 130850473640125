import { useEffect } from "react";
import { navigate } from "gatsby";

import { getDefaultLanguage } from "@helpers";

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getDefaultLanguage();

    navigate(`/${urlLang}`);
  }, []);

  return null;
};

export default IndexPage;
