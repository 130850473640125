export function withWindow<T = void>(
  fn: (window: Window & typeof globalThis) => T
): T | undefined {
  if (typeof window !== "undefined") {
    return fn(window);
  }
  return undefined;
}

export function withNavigator<T = void>(
  fn: (navigator: Navigator) => T
): T | undefined {
  if (typeof navigator !== "undefined") {
    return fn(navigator);
  }
  return undefined;
}

export function showable(showbelow: number = 0) {
  return withWindow((window) => window.scrollY >= showbelow) ?? false;
}

export function getLocation() {
  return withWindow((window) => window.location);
}

export function getNavigatorLanguage() {
  return withNavigator((navigator) => navigator.language);
}

export function getCurrentRoute() {
  return getLocation()?.pathname;
}
