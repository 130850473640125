import type { AppLanguages } from "./langUtils";
import { getCurrentLanguage } from "./langUtils";

import enLang from "./i18n/en.json";
import frLang from "./i18n/fr.json";

export interface ITutorialItem {
  stepid: number;
  title: string;
  link: string;
}

export interface IMenuItem {
  name: string;
  link: string;
}

export interface IIconMenuItem extends IMenuItem {
  icon: string;
}

export interface ITranslations {
  general: {
    previous: string;
    next: string;
    contactUs: string;
    appTitle: string;
  };
  interactiveUI: {
    mainMenu: string;
    topBar: string;
    mainDisplay: string;
    bottomBar: string;
  };
  topMenu: {
    documentation: string;
    menu: string;
    tutorials: string;
    guides: string;
    advancedFeatures: string;
  };
  notes: {
    note: string;
    astuce: string;
    important: string;
    attention: string;
    danger: string;
  };
  userRoles: {
    all: string;
    required: string;
    tec: string;
    tecp: string;
    arc: string;
    arcCoord: string;
    inv: string;
    invCoord: string;
    admLocal: string;
    admGlobal: string;
  };
  searchBar: {
    noPage: string;
    noResult: string;
    placeholder: string;
  };

  tutorials: IMenuItem[];
  guides: IIconMenuItem[];
  tutorialsteps: {
    AtoZ: ITutorialItem[];
    usingdotter: ITutorialItem[];
    [key: string]: ITutorialItem[];
  };
  features: IIconMenuItem[];
}

export function getTranlations(lang?: AppLanguages): ITranslations {
  lang = lang ?? getCurrentLanguage();
  switch (lang) {
    case "fr":
      return frLang;
    default:
      return enLang;
  }
}
