import { exist } from "@ki2/utils";

import { getNavigatorLanguage, getCurrentRoute } from "./browserUtils";

const ALLOWED_LANG: string[] = ["en", "fr"];

export type AppLanguages = "en" | "fr";

export function isAppLanguage(x: string): x is AppLanguages {
  return ALLOWED_LANG.includes(x);
}

export function getDefaultLanguage() {
  const navLang: string = getNavigatorLanguage() ?? "en-US";
  const lang: string = navLang.toLowerCase().split("-")[0];

  if (isAppLanguage(lang)) {
    return lang;
  }

  return "en";
}

interface ILangRoute {
  lang: AppLanguages | null;
  route: string;
}

export function getLangRoute(route?: string | undefined | null): ILangRoute {
  if (!exist(route)) {
    route = getCurrentRoute() ?? "";
  }

  if (route.startsWith("/")) {
    route = route.substring(1);
  }

  const items = route.split("/");

  let ret: ILangRoute = {
    lang: null,
    route: "",
  };

  let routeLang = items[0];
  if (isAppLanguage(routeLang)) {
    ret.lang = routeLang;
    ret.route = items.slice(1).join("/");
  } else {
    ret.route = items.join("/");
  }

  if (ret.route.length > 0 && !ret.route.startsWith("/")) {
    ret.route = "/" + ret.route;
  }

  if (ret.route.endsWith("/")) {
    ret.route = ret.route.substring(0, ret.route.length - 1);
  }

  return ret;
}

export function getCurrentLanguage(): AppLanguages;
export function getCurrentLanguage(withFallback: true): AppLanguages;
export function getCurrentLanguage(withFallback: boolean): AppLanguages | null;
export function getCurrentLanguage(withFallback: boolean = true) {
  const routeLang = getLangRoute().lang;
  if (exist(routeLang)) {
    return routeLang;
  }

  if (withFallback) {
    return getDefaultLanguage();
  }
  return null;
}

export function rewriteLangRoute(lang: AppLanguages, route?: string) {
  const { route: routePath } = getLangRoute(route);

  return `/${lang}${routePath}`;
}

export function genLangRoute(
  route: string,
  lang?: AppLanguages | null | undefined
) {
  lang = lang ?? getCurrentLanguage();

  if (!route.startsWith("/")) {
    route = "/" + route;
  }
  return `/${lang}${route}`;
}
